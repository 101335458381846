import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Avatar, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";

function createData(
  image: string,
  name: string,
  status: string,
  ad_count: number,
  membership: string,
  type: string,
  id: number
) {
  return { image, name, status, ad_count, membership, type, id };
}

const rows = [
  createData(
    "https://i.picsum.photos/id/541/200/200.jpg?hmac=FPYg43H-9McYNZu4fnac6itnn3Ly9eOJRpZLLnyXuVY",
    "Mercedes benz",
    "Active",
    10,
    "Gold",
    "Personal"
  ),
];

const DashboardAds = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("../membership_pending_detail");
  };

  return (
    <div>
      <Sidebar>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Profile Picture</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Ad Count</TableCell>
                <TableCell align="center">Memberships</TableCell>
                <TableCell align="center">Types</TableCell>
                <TableCell align="center">Profile</TableCell>
                <TableCell align="center">Payment Slip</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Avatar
                          alt="Remy Sharp"
                          src="/static/images/avatar/1.jpg"
                          sx={{ width: 50, height: 50 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="paragraph">Ads Name</Typography>
                        <Typography variant="paragraph">2023/01/03</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">{row.ad_count}</TableCell>
                  <TableCell align="center">{row.membership}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      sx={{ backgroundColor: "#cdc4ed", color: "#7148FC" }}
                    >
                      View Profile
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      sx={{ background: "#f9edea", color: "#fc6748" }}
                      onClick={handleOnClick}
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Sidebar>
    </div>
  );
};

export default DashboardAds;
