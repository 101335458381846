import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAs6yzqj487VLo7GtzTbX2rne4CJORgdrE",
  authDomain: "lyf-ads.firebaseapp.com",
  databaseURL: "https://lyf-ads-default-rtdb.firebaseio.com",
  projectId: "lyf-ads",
  storageBucket: "lyf-ads.appspot.com",
  messagingSenderId: "960627708303",
  appId: "1:960627708303:web:319f24e5fb89d2950ffbfa",
  measurementId: "G-14TLLV79YJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db ;