import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import LiveElectronic from "../components/live_electronic";
import LiveVehicle from "../components/live_vehicle";
import LiveBareLand from "../components/live_bareland";
import LiveHouse from "../components/live_house";
import LiveApartment from "../components/live_apartment";
import LiveCommercial from "../components/live_commercial";
import LiveEstate from "../components/live_estate";
import LiveSport from "../components/live_sport";
import LiveMusic from "../components/live_music";
import LiveCollectable from "../components/live_collectable";
import LivePet from "../components/live_pet";
import LiveFurniture from "../components/live_furniture";
import LiveOther from "../components/live_other";
import Sidebar from "../components/sidebar";
import db from "../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

export default function LiveAdView() {
  let { id } = useParams();
  const [ads, Ads] = useState({});
  const [adimages, AdImages] = useState([]);
  const [online, Online] = useState(false);
  const nl2br = require("react-nl2br");

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BASE_URL+"api/ads-info/get-ads-by-adsId", {
        uid: 0,
        adsId: id,
      })
      .then((res) => {
        setOnlineStatus(res.data.response.id_user);
        Ads(res.data.response);
        AdImages(res.data.response.imgList);
      });
  }, [id]);

  function setOnlineStatus(user_id) {
    const q = query(collection(db, "users"), where("id", "==", user_id));
    onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          Online(change.doc.data().isOnline);
        }

        if (change.type === "modified") {
          Online(change.doc.data().isOnline);
        }
      });
    });
  }

  return (
    <>
      <Sidebar>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={5}>
                <Grid item xs={12} md={7}>
                  <Carousel>
                    {adimages.map((item) => (
                      <div key={item.image_name}>
                        <img
                          src={process.env.REACT_APP_BASE_URL+`/${item.img_url}`}
                          style={{ maxHeight: "400px", objectFit: "contain" }}
                          alt={item.image_name}
                        />
                      </div>
                    ))}
                  </Carousel>
                </Grid>

                <Grid item xs={12} md={5}>
                  <Card
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={8} md={8}>
                          <h2>{ads.ads_name}</h2>
                          <h2>{ads.id_user}</h2>
                          <p>LKR {ads.sell_price}</p>
                        </Grid>
                        <Grid item xs={4} md={4} sx={{ textAlign: "right" }}>
                          {ads.isFavorite === 0 ? (
                            <FavoriteBorderOutlinedIcon />
                          ) : (
                            <FavoriteIcon sx={{ color: "#ff1500" }} />
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={8} md={8}>
                          <h3>{ads.location}</h3>
                        </Grid>
                        <Grid item xs={4} md={4} sx={{ textAlign: "right" }}>
                          {ads.created_at}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card
                    sx={{
                      borderRadius: "10px",
                      mt: 4,
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={8} md={8}>
                          <h2>Seller Information</h2>

                          <Grid container spacing={2}>
                            <Grid item xs={2} md={2} sx={{ mt: 1 }}>
                              <Avatar
                                alt="Remy Sharp"
                                src={`/${ads.profile_url}`}
                              />
                            </Grid>
                            <Grid item xs={10} md={10} sx={{ mt: 1 }}>
                              <p style={{ fontSize: "16px" }}>{ads.f_name}</p>
                              <p style={{ fontSize: "12px" }}>
                                Member since {ads.reg_date}
                              </p>
                              <p style={{ fontSize: "12px" }}>
                                {ads.phone_number}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} md={4} sx={{ textAlign: "right" }}>
                          {online === true ? (
                            <CircleIcon sx={{ color: "#50C878" }} />
                          ) : (
                            <CircleIcon sx={{ color: "#FF0000" }} />
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                      {ads.SubCatId === 5 ||
                      ads.SubCatId === 9 ||
                      ads.SubCatId === 10 ||
                      ads.SubCatId === 11 ||
                      ads.SubCatId === 12 ||
                      ads.SubCatId === 91 ||
                      ads.SubCatId === 119 ||
                      ads.SubCatId === 121 ||
                      ads.SubCatId === 122 ||
                      ads.SubCatId === 123 ||
                      ads.SubCatId === 124 ||
                      ads.SubCatId === 125 ||
                      ads.SubCatId === 126 ||
                      ads.SubCatId === 127 ? (
                        <LiveElectronic
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                          brand={ads.brand_name}
                        />
                      ) : ads.SubCatId === 7 || ads.SubCatId === 99 ? (
                        <LiveBareLand
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                          land_size={ads.land_size}
                          land_type={ads.LandTypeName}
                        />
                      ) : ads.SubCatId === 8 || ads.SubCatId === 100 ? (
                        <LiveHouse
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                          land_size={ads.land_size}
                          land_type={ads.LandTypeName}
                          house_size={ads.house_size}
                          bedroom={ads.bed_room_count}
                          bathroom={ads.bathroom_count}
                          furniture={
                            ads.furniture_avalible === 1 ? "Yes" : "No"
                          }
                        />
                      ) : ads.SubCatId === 17 || ads.SubCatId === 101 ? (
                        <LiveApartment
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                          bedroom={ads.bed_room_count}
                          bathroom={ads.bathroom_count}
                          floor_number={ads.floor_number}
                          house_size={ads.house_size}
                        />
                      ) : ads.SubCatId === 18 || ads.SubCatId === 102 ? (
                        <LiveCommercial
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                          land_size={ads.land_size}
                          land_type={ads.LandTypeName}
                          permises={ads.permises}
                        />
                      ) : ads.SubCatId === 19 || ads.SubCatId === 103 ? (
                        <LiveEstate
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                          land_size={ads.land_size}
                          land_type={ads.LandTypeName}
                          crop_name={ads.crop_name}
                          banglow_avalibilty={
                            ads.banglow_avalibilty === 1 ? "Yes" : "No"
                          }
                        />
                      ) : ads.SubCatId === 1 ||
                        ads.SubCatId === 2 ||
                        ads.SubCatId === 3 ||
                        ads.SubCatId === 13 ||
                        ads.SubCatId === 14 ||
                        ads.SubCatId === 15 ||
                        ads.SubCatId === 16 ||
                        ads.SubCatId === 92 ||
                        ads.SubCatId === 93 ||
                        ads.SubCatId === 94 ||
                        ads.SubCatId === 95 ||
                        ads.SubCatId === 96 ||
                        ads.SubCatId === 97 ? (
                        <LiveVehicle
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                          brand={ads.vehical_brand}
                          model={ads.vehical_model}
                          year={ads.manifacture_year}
                          mileage={ads.mileage}
                          transmission={ads.TransmissionTypeName}
                          fuel={ads.fuel_name}
                          engine_capacity={ads.eng_capacity}
                          color={ads.name}
                        />
                      ) : ads.SubCatId === 20 || ads.SubCatId === 110 ? (
                        <LiveSport
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                          type={ads.type_name}
                        />
                      ) : ads.SubCatId === 22 || ads.SubCatId === 112 ? (
                        <LiveCollectable
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                        />
                      ) : ads.SubCatId === 21 || ads.SubCatId === 111 ? (
                        <LiveMusic
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                          type={ads.type_name}
                        />
                      ) : ads.SubCatId === 23 ||
                        ads.SubCatId === 24 ||
                        ads.SubCatId === 25 ||
                        ads.SubCatId === 26 ||
                        ads.SubCatId === 27 ||
                        ads.SubCatId === 28 ||
                        ads.SubCatId === 113 ||
                        ads.SubCatId === 114 ||
                        ads.SubCatId === 115 ||
                        ads.SubCatId === 116 ||
                        ads.SubCatId === 117 ||
                        ads.SubCatId === 118 ? (
                        <LivePet
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                        />
                      ) : ads.SubCatId === 130 || ads.SubCatId === 131 ? (
                        <LiveFurniture
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                          condition={ads.condition_name}
                        />
                      ) : (
                        <LiveOther
                          category={ads.category_name}
                          subcategory={ads.sub_cat_name}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography sx={{ mb: 2 }} variant="h6">
                    Description
                  </Typography>
                  {nl2br(ads.AdsDescription)}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Sidebar>
    </>
  );
}
