import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import Sidebar from "../components/sidebar";

const handleSubmit = () => {
  alert("Submitted!");
};

const AdPayment = () => {
  return (
    <div>
      <Sidebar>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4}></Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="h5">Payment Verification</Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="aTitle"
                name="aTitle"
                label="Payment Slip Number"
                autoComplete="ad title"
                sx={{ mb: 2 }}
              />

              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="aTitle"
                name="aTitle"
                label="Payment Method"
                autoComplete="ad title"
                sx={{ mb: 2 }}
              />

              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="aTitle"
                name="aTitle"
                label="Payment Type"
                autoComplete="ad title"
                sx={{ mb: 2 }}
              />

              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="aTitle"
                name="aTitle"
                label="Amount Paid"
                autoComplete="ad title"
                sx={{ mb: 2 }}
              />

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="mMethod"
                    name="mMethod"
                    label="Slip Sceenshot"
                    autoComplete="payment method"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Download Slip
                  </Button>
                </Grid>
              </Grid>

              <Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="span">Status</Typography>
                  <Button
                    disableElevation
                    variant="outlined"
                    sx={{
                      backgroundColor: "#d4f2e6",
                      color: "#01C67A",
                      width: "100%",
                    }}
                  >
                    Status Verified
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={2} sx={{ mt: 4 }}>
              <Grid item xs={12} sm={6} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ background: "#f9edea", color: "#fc6748", mr: 4 }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ background: "#f9edea", color: "#fc6748", mr: 4 }}
                >
                  Approve
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}></Grid>
        </Grid>
      </Sidebar>
    </div>
  );
};

export default AdPayment;
