import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PendingAds from "../pages/advertisement_pending";
import LivedAds from "../pages/advertisement_live";
import RejectdAds from "../pages/advertisement_reject";
import Sidebar from "../components/sidebar";

export default function LabTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Sidebar>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Pending Ads" value="1" />
              <Tab label="Live Ads" value="2" />
              <Tab label="Rejected Ads" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <PendingAds />
          </TabPanel>
          <TabPanel value="2">
            <LivedAds />
          </TabPanel>
          <TabPanel value="3">
            <RejectdAds />
          </TabPanel>
        </TabContext>
      </Box>
    </Sidebar>
  );
}
