import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../components/sidebar";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

const Catalouge = () => {
  const [categories, Categories] = useState([]);
  const [subcategories, SubCategories] = useState([]);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BASE_URL+"api/category/get-main", {
        lan_code: "EN",
      })
      .then((res) => {
        Categories(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubCategories = (id) => {
    axios
      .post(process.env.REACT_APP_BASE_URL+"api/category/get-sub_by_catid", {
        lan_code: "EN",
        category_id: id,
      })
      .then((res) => {
        SubCategories(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Sidebar>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: "10px",
                  border: 1,
                }}
              >
                <CardHeader
                  sx={{ borderBottom: 1 }}
                  title="Categories"
                  action={<CreateIcon sx={{ marginTop: 1 }} />}
                />

                <CardContent>
                  <List>
                    {categories.map((item) => (
                      <div key={item.id_ads_category}>
                        <ListItem disablePadding>
                          <ListItemButton  onClick={() =>
                                handleSubCategories(item.id_ads_category)
                              }>
                            <ListItemText primary={item.category_name} />
                            <CreateIcon
                              sx={{ color: "#fc6748" }}
                            />
                            <DeleteIcon sx={{ color: "#443688" }} />
                          </ListItemButton>
                        </ListItem>
                      </div>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: "10px",
                  border: 1,
                }}
              >
                <CardHeader sx={{ borderBottom: 1 }} title="Sub Categories" />

                <CardContent>
                  <List>
                    {subcategories.length !== 0 &&
                      subcategories.map((item) => (
                        <div key={item.id_sub_category}>
                          <ListItem disablePadding>
                            <ListItemButton>
                              <ListItemText primary={item.sub_cat_name} />
                              <CreateIcon sx={{ color: "#fc6748" }} />
                              <DeleteIcon sx={{ color: "#443688" }} />
                            </ListItemButton>
                          </ListItem>
                        </div>
                      ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Sidebar>
    </div>
  );
};

export default Catalouge;
