import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormHelperText,
  Grid,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
// import React, { useState } from "react";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircleTwoTone";
import Sidebar from "../components/sidebar";

export default function UserPaymentSlip() {
  // const [loginName, setLoginName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("worked!");
  };

  return (
    <div>
      <Sidebar>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: "10px",
                  background: "#F8F8F8",
                  padding: "20px",
                }}
              >
                <CardContent>
                  <Typography variant="h5">Payment Verification</Typography>

                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="aTitle"
                      name="aTitle"
                      label="Payment Slip Number"
                      autoComplete="ad title"
                      sx={{ mb: 2 }}
                    />

                    <TextField
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="aTitle"
                      name="aTitle"
                      label="Payment Method"
                      autoComplete="ad title"
                      sx={{ mb: 2 }}
                    />

                    <TextField
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="aTitle"
                      name="aTitle"
                      label="Payment Type"
                      autoComplete="ad title"
                      sx={{ mb: 2 }}
                    />

                    <TextField
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="aMtype"
                      name="aMtype"
                      label="Membership Type"
                      autoComplete="membership type"
                      sx={{ mb: 2 }}
                    />

                    <TextField
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="aTitle"
                      name="aTitle"
                      label="Amount Paid"
                      autoComplete="ad title"
                      sx={{ mb: 2 }}
                    />

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          size="small"
                          margin="normal"
                          required
                          fullWidth
                          id="mMethod"
                          name="mMethod"
                          label="Slip Sceenshot"
                          autoComplete="payment method"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 2, mb: 4 }}
                        >
                          Download Slip
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="span">Status</Typography>
                        <Button
                          disableElevation
                          variant="outlined"
                          sx={{
                            backgroundColor: "#d4f2e6",
                            color: "#01C67A",
                            width: "100%",
                          }}
                        >
                          Status Verified
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ borderRadius: "10px" }}>
                <Grid container sx={{ mt: 3 }}>
                  <Grid item xs={4} sm={4}></Grid>
                  <Grid item xs={4} sm={4}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: 110, height: 110 }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}></Grid>
                </Grid>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ textAlign: "center" }}
                  >
                    John Doe
                  </Typography>
                  <Typography
                    variant="span"
                    component="div"
                    sx={{ textAlign: "center", mb: 3 }}
                  >
                    johndoe@gmail.com
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ textAlign: "center", mb: 6 }}
                  >
                    Gold Member - 100
                  </Typography>

                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Chip
                      label="Verified"
                      sx={{
                        background: "#a4e6c1",
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Sidebar>
    </div>
  );
}
