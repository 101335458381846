import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import axios from "axios";
import ApproveButton from "../components/approve_button";
import { useParams } from "react-router-dom";

const PendingAds = () => {
  const data = useParams();
  const [rows, pendingAds] = useState([]);
  const perPage = 10;

  const [pagination, setPagination] = useState({
    count: 0,
    limit: perPage,
    offset: 0,
  });

  useEffect(() => {
    loadPendingAd(1, 10, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (event, page) => {
    let limit = (page - 1) * perPage;
    let offset = (page - 1) * perPage;

    setPagination({ ...pagination, limit: limit, offset: offset });
    loadPendingAd(1, pagination.limit, offset);
  };

  function loadPendingAd(uid, limit, offset) {
    axios
      .post(process.env.REACT_APP_BASE_URL+"api/ads-info/get-pendingads", {
        uid: uid,
        limit: limit,
        offset: offset,
        lan_code: data.language,
      })
      .then((res) => {
        if (res.data.response.length !== 0) {
          pendingAds(res.data.response);
          setPagination({
            ...pagination,
            count: res.data.totalrecords,
          });
        } else {
          pendingAds([]);
          setPagination({
            ...pagination,
            count: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Ad title</TableCell>
              <TableCell align="center">User Name</TableCell>
              <TableCell align="center">Contact</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.ADID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid item xs={12} md={3}>
                      <img
                        alt="not found"
                        src={process.env.REACT_APP_BASE_URL+ row.imgList[0]?.img_url}
                        className="imgThumbnail"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      >
                        {row.ads_name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: "#909090",
                        }}
                      >
                        {Date(row.date)}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center">{row.f_name}</TableCell>
                <TableCell align="center">{row.tel_number}</TableCell>
                <TableCell align="center">
                  <Grid>
                    <ApproveButton
                      id={row.ADID}
                      catid={row.id_ads_category}
                      subid={row.id_sub_category}
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f9edea",
                        color: "#fc6748",
                        mr: 1,
                      }}
                    >
                      Reject
                    </Button>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={Math.ceil(pagination.count / perPage)}
        onChange={handlePageChange}
        sx={{ mt: 2 }}
      />
    </div>
  );
};

export default PendingAds;
