import { Grid, Typography } from "@mui/material";
import React from "react";

export default function LiveElectronic(data) {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>Details</Typography>
      <Grid container spacing={5}>
        <Grid item xs={3} md={3}>
          Category : {data.category}
        </Grid>
        <Grid item xs={3} md={3}>
          Sub Category : {data.subcategory}
        </Grid>
        <Grid item xs={3} md={3}>
          Condition : {data.condition}
        </Grid>
        <Grid item xs={3} md={3}>
          Brand : {data.brand}
        </Grid>
      </Grid>
    </>
  );
}
