import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Advertisement from "./pages/advertisement";
import Catalouge from "./pages/catalouge";
import Banners from "./pages/banners";
import Membership from "./pages/membership";
import Verification from "./pages/verification";
import Admin from "./pages/admin";
import Settings from "./pages/settings";
import Vouchers from "./pages/vouchers";
import MembershipDetails from "./pages/membership_pending_detail";
import EditAdvertisment from "./pages/advertisement_edit";
import AdvertisementPayment from "./pages/advertisement_payment";
import PendingEstate from "./components/pending_estate";
import PendingVehicle from "./components/pending_vehicle";
import PendingCommercial from "./components/pending_commercial";
import PendingApartment from "./components/pending_apartment";
import PendingHouse from "./components/pending_house";
import BareLand from "./components/pending_bareland";
import Electronic from "./components/pending_electronic";
import PendingPet from "./components/pending_pet";
import PendingSport from "./components/pending_sport";
import PendingMusic from "./components/pending_music";
import PendingOther from "./components/pending_other";
import PendingFurniture from "./components/pending_furniture";
import AdPayment from "./pages/advertisement_payment";
import LiveAdView from "./pages/live_ad_view";
import UserPaymentSlip from "./pages/user_payment_slip";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              localStorage.getItem("token") !== null ? <Dashboard /> : <Login />
            }
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/advertisement/:language" element={<Advertisement />} />
          <Route
            path="/advertisementsn/:language"
            element={<Advertisement />}
          />
          <Route path="/catalouge" element={<Catalouge />} />
          <Route path="/banners" element={<Banners />} />
          <Route path="/vouchers" element={<Vouchers />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="/membership_pending_detail"
            element={<MembershipDetails />}
          />
          <Route
            path="/edit_advertisement/:id/:catId/:subCatId"
            element={<EditAdvertisment />}
          />
          <Route
            path="/advertisement_payment"
            element={<AdvertisementPayment />}
          />
          <Route
            path="/pending_estate/:id/:subCatId"
            element={<PendingEstate />}
          />
          <Route
            path="/pending_vehicle/:id/:subCatId"
            element={<PendingVehicle />}
          />
          <Route
            path="/pending_commercial/:id/:subCatId"
            element={<PendingCommercial />}
          />
          <Route
            path="/pending_apartment/:id/:subCatId"
            element={<PendingApartment />}
          />
          <Route
            path="/pending_house/:id/:subCatId"
            element={<PendingHouse />}
          />
          <Route
            path="/pending_bareland/:id/:subCatId"
            element={<BareLand />}
          />
          <Route
            path="/pending_electronic/:id/:subCatId"
            element={<Electronic />}
          />
          <Route path="/pending_pet/:id/:subCatId" element={<PendingPet />} />
          <Route
            path="/pending_sport/:id/:subCatId"
            element={<PendingSport />}
          />
          <Route
            path="/pending_music/:id/:subCatId"
            element={<PendingMusic />}
          />
          <Route
            path="/pending_other/:id/:subCatId"
            element={<PendingOther />}
          />
          <Route
            path="/pending_furniture/:id/:subCatId"
            element={<PendingFurniture />}
          />
          <Route path="/advertisement_payment" element={<AdPayment />} />

          <Route path="/live_ad_view/:id/:subCatId" element={<LiveAdView />} />

          <Route path="/paymentslip/:id" element={<UserPaymentSlip />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
