import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Typography } from "@mui/material";

const DashboardAds = () => {
  const effectRan = useRef(false);
  const [recentads, recentAds] = useState([]);

  const fetchQuotes = async () => {
    if (effectRan.current === false) {
      try {
        const res = await axios.post(process.env.REACT_APP_BASE_URL+
          "api/ads-info/recent-ads",
          {
            uid: 1,
            limit: 25,
            offset: 0,
            lan_code: "EN",
          }
        );
        recentAds(res.data.response);
      } catch (err) {
        console.log(err);
      }
      effectRan.current = true;
    }
  };

  useEffect(() => {
    fetchQuotes();
  }, []);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Ad Title</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Seller Name</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentads.map((row) => (
              <TableRow
                key={row.id_ads_info}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid item xs={12} md={2}>
                      <img
                        alt="not found"
                        src={process.env.REACT_APP_BASE_URL+ row.imgList[0].img_url}
                        className="imgThumbnail"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      >
                        {row.ads_name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: "#909090",
                        }}
                      >
                        {row.date}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  {row.sell_price.toLocaleString()}
                </TableCell>
                <TableCell align="center">{row.f_name}</TableCell>
                <TableCell align="center">
                  {row.AdsSattus === 1 ? "Active" : "Deactive"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DashboardAds;
