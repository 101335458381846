import { Grid, Typography } from "@mui/material";
import * as React from "react";
import DashboardCards from "../components/dashboard_cards";
import DashboardCharts from "../components/dashboard_charts";
import DashboardAds from "../components/dashboard_recentads";
import Sidebar from "../components/sidebar";

const Dashboard = () => {
  console.log(localStorage.getItem("token"))
  return (
    <div>
      <Sidebar>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <DashboardCards />
          </Grid>

          <Grid item xs={12}>
            <DashboardCharts />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Most Recent Advertisment
            </Typography>
            <DashboardAds />
          </Grid>
        </Grid>
      </Sidebar>
    </div>
  );
};

export default Dashboard;
