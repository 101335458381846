import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const LivedAds = () => {
  const data = useParams();
  const [rows, LiveAds] = useState([]);
  const perPage = 10;

  const [pagination, setPagination] = useState({
    count: 0,
    limit: perPage,
    offset: 0,
  });

  useEffect(() => {
    loadLiveAd(1, 10, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (event, page) => {
    let limit = (page - 1) * perPage;
    let offset = (page - 1) * perPage;

    setPagination({ ...pagination, limit: limit, offset: offset });
    loadLiveAd(1, pagination.limit, offset);
  };

  function loadLiveAd(uid, limit, offset) {
    axios
      .post(process.env.REACT_APP_BASE_URL+"api/ads-info/get-liveads", {
        uid: uid,
        limit: limit,
        offset: offset,
        lan_code: data.language,
      })
      .then((res) => {
        if (res.data.response.length !== 0) {
          LiveAds(res.data.response);
          setPagination({
            ...pagination,
            count: res.data.totalrecords,
          });
        } else {
          LiveAds([]);
          setPagination({
            ...pagination,
            count: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleAdDelete = (adid) => {
    confirmAlert({
      title: "Are You Sure?",
      message: "There is no way to undo this action!!",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            axios
              .post(process.env.REACT_APP_BASE_URL+"api/ads-info/delete-ad", {
                adsId: adid,
              })
              .then((res) => {
                if (res.data.response.length !== 0) {
                  loadLiveAd(1, 10, 0);
                }
              })
              .catch((err) => {
                console.log(err);
              }),
        },
        {
          label: "No",
          // onClick: () => alert("Click No")
        },
      ],
    });
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Ad title</TableCell>
              <TableCell align="center">User Name</TableCell>
              <TableCell align="center">Contact</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.ADID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Grid spacing={6} container sx={{ alignItems: "center" }}>
                    <Grid item xs={12} md={3}>
                      <img
                        alt="not found"
                        src={process.env.REACT_APP_BASE_URL+ row.imgList[0]?.img_url}
                        className="imgThumbnail"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      >
                        {row.ads_name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: "#909090",
                        }}
                      >
                        {Date(row.date)}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center">{row.f_name}</TableCell>
                <TableCell align="center">{row.tel_number}</TableCell>
                <TableCell align="center">
                  <Grid>
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: "#d4f2e6",
                        color: "#01C67A",
                        mr: 1,
                      }}
                      component={Link}
                      to={{
                        pathname:
                          `/${
                            row.id_sub_category === 19 ||
                            row.id_sub_category === 103
                              ? "live_ad_view" // estate
                              : row.id_sub_category === 18 ||
                                row.id_sub_category === 102
                              ? "live_ad_view" //LiveCommercial
                              : row.id_sub_category === 17 ||
                                row.id_sub_category === 101
                              ? "live_ad_view" //LiveApartment
                              : row.id_sub_category === 8 ||
                                row.id_sub_category === 100
                              ? "live_ad_view" //LiveHouse
                              : row.id_sub_category === 7 ||
                                row.id_sub_category === 99
                              ? "live_ad_view" //bareland
                              : row.id_sub_category === 1 ||
                                row.id_sub_category === 2 ||
                                row.id_sub_category === 3 ||
                                row.id_sub_category === 13 ||
                                row.id_sub_category === 14 ||
                                row.id_sub_category === 15 ||
                                row.id_sub_category === 16 ||
                                row.id_sub_category === 92 ||
                                row.id_sub_category === 93 ||
                                row.id_sub_category === 94 ||
                                row.id_sub_category === 95 ||
                                row.id_sub_category === 96 ||
                                row.id_sub_category === 97
                              ? "live_ad_view" //LiveVehicle
                              : row.id_sub_category === 5 ||
                                row.id_sub_category === 9 ||
                                row.id_sub_category === 10 ||
                                row.id_sub_category === 11 ||
                                row.id_sub_category === 12 ||
                                row.id_sub_category === 91 ||
                                row.id_sub_category === 119 ||
                                row.id_sub_category === 121 ||
                                row.id_sub_category === 122 ||
                                row.id_sub_category === 123 ||
                                row.id_sub_category === 124 ||
                                row.id_sub_category === 125 ||
                                row.id_sub_category === 126 ||
                                row.id_sub_category === 127
                              ? "live_ad_view" //LiveElectronic
                              : row.id_sub_category === 23 ||
                                row.id_sub_category === 24 ||
                                row.id_sub_category === 25 ||
                                row.id_sub_category === 26 ||
                                row.id_sub_category === 27 ||
                                row.id_sub_category === 28 ||
                                row.id_sub_category === 113 ||
                                row.id_sub_category === 114 ||
                                row.id_sub_category === 115 ||
                                row.id_sub_category === 116 ||
                                row.id_sub_category === 117 ||
                                row.id_sub_category === 118
                              ? "live_ad_view" //LivePet
                              : row.id_sub_category === 20 ||
                                row.id_sub_category === 110
                              ? "live_ad_view" //LiveSport
                              : row.id_sub_category === 21 ||
                                row.id_sub_category === 111
                              ? "live_ad_view" //LiveMusic
                              : row.id_sub_category === 22 ||
                                row.id_sub_category === 112
                              ? "live_ad_view" // collectables ads
                              : row.id_sub_category === 129 ||
                                row.id_sub_category === 112
                              ? "live_ad_view" // other ads
                              : row.id_sub_category === 130 ||
                                row.id_sub_category === 131
                              ? "live_ad_view" //LiveFurniture
                              : ""
                          }/` +
                          row.ADID +
                          "/" +
                          row.id_sub_category,
                      }}
                    >
                      View
                    </Button>

                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f9edea",
                        color: "#fc6748",
                        mr: 1,
                      }}
                      onClick={() => handleAdDelete(row.ADID)}
                    >
                      Delete
                    </Button>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={Math.ceil(pagination.count / perPage)}
        onChange={handlePageChange}
        sx={{ mt: 2 }}
      />
    </div>
  );
};

export default LivedAds;
