import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../components/sidebar";

const Verification = () => {
  const [users, Users] = useState([]);
  const perPage = 10;

  const [pagination, setPagination] = useState({
    count: 0,
    limit: perPage,
    offset: 0,
  });

  useEffect(() => {
    loadUsers(1, 10, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (event, page) => {
    let limit = (page - 1) * perPage;
    let offset = (page - 1) * perPage;

    setPagination({ ...pagination, limit: limit, offset: offset });
    loadUsers(1, pagination.limit, offset);
  };

  function loadUsers(uid, limit, offset) {
    axios
      .get(process.env.REACT_APP_BASE_URL+"api/user/get-all", {
        headers: {
          dataType: "json",
          processData: false,
          contentType: false,
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.response.length !== 0) {
          Users(res.data.response);
          setPagination({
            ...pagination,
            count: res.data.totalrecords,
          });
        } else {
          Users([]);
          setPagination({
            ...pagination,
            count: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <Sidebar>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Profile Picture</TableCell>
                <TableCell align="center">User Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">User privillage</TableCell>
                <TableCell align="center">View Payment Slip</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow
                  key={row.ADID}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Grid container sx={{ alignItems: "center" }}>
                      <Grid item xs={12} md={3}>
                        <Avatar
                          src={process.env.REACT_APP_BASE_URL+ row?.profile_url}
                          sx={{ width: 75, height: 75 }}
                        ></Avatar>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="center">{row.f_name}</TableCell>
                  <TableCell align="center">{row.email_address}</TableCell>
                  <TableCell align="center">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Privillage
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={10}
                          label="Privillage"
                          size="small"
                        >
                          <MenuItem value={10}>Verified</MenuItem>
                          <MenuItem value={20}>Rejected</MenuItem>
                          <MenuItem value={30}>Pending</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Grid>
                      <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: "#f9edea",
                          color: "#fc6748",
                          mr: 1,
                        }}
                        component={Link}
                        to={{
                          pathname: `/paymentslip/` + row.id_user,
                        }}
                      >
                        View Details
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          count={Math.ceil(pagination.count / perPage)}
          onChange={handlePageChange}
          sx={{ mt: 2 }}
        />
      </Sidebar>
    </div>
  );
};

export default Verification;
