import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function ApproveButton(data) {
  const adid = data.id;
  // const catid = this.props.catid;
  const subid = data.subid;
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          backgroundColor: "#d4f2e6",
          color: "#01C67A",
          mr: 1,
        }}
        component={Link}
        to={{
          pathname:
            `/${
              subid === 19
                ? "pending_estate"
                : subid === 18
                ? "pending_commercial"
                : subid === 17
                ? "pending_apartment"
                : subid === 8
                ? "pending_house"
                : subid === 7
                ? "pending_bareland"
                : subid === 1 || subid === 2 || subid === 3 || subid === 13 || subid === 14 || subid === 15 || subid === 16 || subid === 131
                ? "pending_vehicle"
                : subid === 5 || subid === 9 || subid === 10 || subid === 11 || subid === 12 || subid === 91 || subid === 119
                ? "pending_electronic"
                : subid === 23 || subid === 24 || subid === 25 || subid === 26 || subid === 27 || subid === 28
                ? "pending_pet"
                : subid === 20
                ? "pending_sport"
                : subid === 21
                ? "pending_music"
                : subid === 22
                ? "pending_other" // collectables ads
                : subid === 129
                ? "pending_other" // other ads
                : subid === 130
                ? "pending_furniture"
                : ''
            }/` +
            adid +
            "/" +
            subid,
        }}
      >
        Approve
      </Button>
    </>
  );
}
