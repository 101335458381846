import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  ImageList,
  ImageListItem,
  TextField,
  FormLabel,
  Paper,
  FormControlLabel,
  Radio,
  Divider,
  InputBase,
  RadioGroup,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Box } from "@mui/system";
import Sidebar from "../components/sidebar";
import { confirmAlert } from "react-confirm-alert";
import db from "../firebase";
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";

export default function PendingSport(data) {
  const navigate = useNavigate();

  let { id } = useParams();
  const [sporttype, SportType] = useState([]);
  const [sporttypesn, SportTypeSN] = useState([]);
  const [stype, sType] = useState(0);
  const [ssntype, sSNType] = useState(0);

  const [adname, adName] = useState("");
  const [adsntitle, adSNName] = useState("");
  const [addescription, adDescription] = useState("");
  const [adsndescription, adSNDescription] = useState("");
  const [adsubid, adSubId] = useState(0);
  const [price, Price] = useState(0);
  const [adimglist, adimgList] = useState([]);
  const [category, categorySet] = useState([]);
  const [categorysnset, categorySNSet] = useState([]);
  const [categoryid, categoryId] = useState(0);
  const [categorysnid, categorySNId] = useState(0);
  const [subcategoryset, subCategorySet] = useState([]);
  const [subcategorysnset, subCategorySNSet] = useState([]);
  const [subcategoryid, subCategoryId] = useState(0);
  const [subcategorysnId, subCategorySNId] = useState(0);
  const [locationset, locationSet] = useState([]);
  const [locationsnset, locationSNSet] = useState([]);
  const [locationid, locationId] = useState(0);
  const [locationsnid, locationSNId] = useState(0);
  const [selectedvalue, setSelectedValue] = useState(3);
  const [selectedsnvalue, setSNSelectedValue] = useState(3);
  const [aduser, adUser] = useState(0);
  const [addate, adDate] = useState("");

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BASE_URL+"api/category/get-main", {
        lan_code: "EN",
      })
      .then((res) => {
        categorySet(res.data.response);
      });

    axios
      .post(process.env.REACT_APP_BASE_URL+"api/category/get-main", {
        lan_code: "SN",
      })
      .then((res) => {
        categorySNSet(res.data.response);
      });

    axios
      .post(process.env.REACT_APP_BASE_URL+"api/category/get-main-sub", {
        lan_code: "EN",
      })
      .then((res) => {
        subCategorySet(res.data.response);
      });

    axios
      .post(process.env.REACT_APP_BASE_URL+"api/area/get-all", {
        lan_code: "EN",
      })
      .then((res) => {
        locationSet(res.data.response);
      });

    axios
      .post(process.env.REACT_APP_BASE_URL+"api/area/get-all", {
        lan_code: "SN",
      })
      .then((res) => {
        locationSNSet(res.data.response);
      });

    axios
      .post(process.env.REACT_APP_BASE_URL+"api/sport-type/get-all", {
        lan_code: "EN",
      })
      .then((res) => {
        SportType(res.data.response);
      });

    axios
      .post(process.env.REACT_APP_BASE_URL+"api/sport-type/get-all", {
        lan_code: "SN",
      })
      .then((res) => {
        SportTypeSN(res.data.response);
      });

    axios
      .post(process.env.REACT_APP_BASE_URL+"api/ads-info/get-ads-by-adsId", {
        uid: 0,
        adsId: id,
      })
      .then((res) => {
        adName(res.data.response.ads_name);
        adDescription(res.data.response.AdsDescription);
        adimgList(res.data.response.imgList);
        categoryId(res.data.response.CatId);
        subCategoryId(res.data.response.SubCatId);
        locationId(res.data.response.location);
        setSelectedValue(res.data.response.id_conditions);
        Price(res.data.response.sell_price);
        adSubId(res.data.response.IdAdsSubInfo);
        sType(res.data.response.id_sport_type);
        adUser(res.data.response.id_user);
        adDate(res.data.response.created_at);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubCategory = (event) => {
    subCategoryId(event.target.value);
  };

  const handleSubCategorySN = (event) => {
    subCategorySNId(event.target.value);
  };

  const handleTitle = (event) => {
    adName(event.target.value);
  };

  const handleTitleSN = (event) => {
    adSNName(event.target.value);
  };

  const handleDescription = (event) => {
    adDescription(event.target.value);
  };

  const handleDescriptionSN = (event) => {
    adSNDescription(event.target.value);
  };

  const handleLocation = (event) => {
    locationId(event.target.value);
  };

  const handleLocationSN = (event) => {
    locationSNId(event.target.value);
  };

  const handleRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleRadioSN = (event) => {
    setSNSelectedValue(event.target.value);
  };

  const handlePrice = (event) => {
    Price(event.target.value);
  };

  const handleSportType = (event) => {
    sType(event.target.value);
  };

  const handleSportTypeSN = (event) => {
    sSNType(event.target.value);
  };

  const loadSubCategory = (event) => {
    categorySNId(event.target.value);
    axios
      .post(process.env.REACT_APP_BASE_URL+"api/category/get-sub_by_catid", {
        lan_code: "SN",
        category_id: event.target.value,
      })
      .then((res) => {
        subCategorySNSet(res.data.response);
      }, []);
  };

  const removeImage = (id, index) => {
    let formData = new FormData();
    formData.append("imageId", id);

    confirmAlert({
      title: "Are You Sure?",
      message: "There is no way to undo this action!!",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            axios
              .delete("api/ads-image/delete", formData, {
                headers: {
                  dataType: "json",
                  processData: false,
                  contentType: false,
                  Authorization: localStorage.getItem("token"),
                },
              })
              .then((res) => {
                if (res.data.response.length !== 0) {
                  adimglist.splice(index, 1);
                  const newList = adimglist.filter((item) => item.id !== id);
                  adimgList(newList);
                  alert(res.data.response);
                }
              }, []),
        },
        {
          label: "No",
          // onClick: () => alert("Click No")
        },
      ],
    });
  };

  const handleSubmit = () => {
    const imageArr = [];
    for (let i = 0; i < adimglist.length; i++) {
      imageArr.push(adimglist[i].img_url);
    }

    let params = {
      ads_name: adname,
      description: addescription,
      sell_price: price,
      location: locationid,
      conditonId: selectedvalue,
      adsId: id,
      mainCatId: categoryid,
      subCatId: subcategoryid,
      id_ads_sub_info: adsubid,
      id_sport_type: stype,
    };

    axios
      .put("api/ads-info/update-sport-ads", params, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {});

    let formData = new FormData();
    formData.append("ads_name", adsntitle);
    formData.append("description", adsndescription);
    formData.append("sell_price", price);
    formData.append("location", locationsnid);
    formData.append("mainCatId", categorysnid);
    formData.append("subCatId", subcategorysnId);
    formData.append("conditonId", selectedsnvalue);
    formData.append("id_sport_type", ssntype);
    formData.append("id_user", 1091);
    formData.append("lan_code", "SN");
    formData.append("phone_number", "0773156522");
    formData.append("status", 1);
    formData.append("calls_only", 1);
    formData.append("type", "translate");

    for (let j = 0; j < imageArr.length; j++) {
      formData.append("imageData", imageArr[j]);
    }

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    axios
      .post(process.env.REACT_APP_BASE_URL+"api/ads-info/create-sport-ads", formData, {
        headers: {
          dataType: "json",
          processData: false,
          contentType: false,
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        getApprove(id);
      });
  };

  function getApprove(adId) {
    axios
      .post(process.env.REACT_APP_BASE_URL+
        "api/ads-info/approve",
        { adsId: adId },
        {
          headers: {
            dataType: "json",
            processData: false,
            contentType: false,
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.response.length !== 0) {
          saveAdDataInFirebase(aduser, addate, adId, adname, adimglist);
          setNotifications(aduser, adname, adimglist, adId);
        } else {
          alert("Oops.. something went wrong!!");
        }
      });
  }

  async function saveAdDataInFirebase(aduser, addate, adId, adname, adimglist) {
    const q = query(collection(db, "users"), where("id", "==", aduser));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // user not exist. so add notification collection to firestore
      try {
        //create notification collection
        var count = 0;
        var imageUrl = "";
        for (var i = 0; i < adimglist.length; i++) {
          if (count === 0) {
            imageUrl = "/" + adimglist[i].img_url;
          }
          count++;
        }

        // save data in firestore
        let collRef = await collection(db, "users/" + aduser + "/notifications");
        await addDoc(collRef, {
          adId: parseFloat(adId),
          date: addate,
          imageUrl: imageUrl,
          isRead: false,
          content: adname + " is approved",
          type: "AdsStateChanged",
          title: "Your ad is approved",
        });
        console.log("a new coll has been created");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }

  async function setNotifications(aduser, adname, adimglist, adId) {
    let user_device_token;
    let count = 0;
    let imageUrl;

    const q = query(
      collection(db, "users"),
      where("id", "==", parseFloat(aduser))
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      try {
        querySnapshot.forEach((doc) => {
          user_device_token = doc.data().deviceTokens[0];
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }

    if (typeof user_device_token == "undefined") {
      user_device_token = ["1234"];
    }

    for (var i = 0; i < adimglist.length; i++) {
      if (count === 0) {
        imageUrl = "/" + adimglist[i].img_url;
      }
      count++;
    }

    if (user_device_token !== "") {
      var datas = {
        title: adname,
        body: adname + " is approved successfully.",
        imgUrl: imageUrl,
        deviceTokens: user_device_token,
        adsId: adId,
      };

      axios
        .post(process.env.REACT_APP_BASE_URL+
          "/notification/set-push-notification",
          datas,
          {
            headers: {
              dataType: "json",
              processData: false,
              contentType: false,
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.response.length !== 0) {
            alert("Notification Sent Successfully!!");
            alert("Ad Approved Successfully!!");
            navigate("../advertisement_payment");
          } else {
            alert("Oops.. something went wrong!!");
          }
        });
    } else {
      alert("Notofication Not Sent!!");
    }
  }

  return (
    <>
      <Sidebar>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h5" sx={{ ml: 5 }}>
              Edit The Ad
            </Typography>

            <Box noValidate sx={{ padding: 5 }}>
              <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                <InputLabel id="demo-select-small">Select Category</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="aCategory"
                  label="Select Category"
                  fullWidth
                  value={categoryid}
                >
                  {category &&
                    category.map((category) => (
                      <MenuItem
                        key={category.id_ads_category}
                        value={category.id_ads_category}
                      >
                        {category.category_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">
                  Select Sub Category
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="aCategory"
                  value={subcategoryid}
                  label="Select Sub Category"
                  fullWidth
                  onChange={handleSubCategory}
                >
                  {subcategoryset &&
                    subcategoryset.map((subcategory) => (
                      <MenuItem
                        key={subcategory.id_sub_category}
                        value={subcategory.id_sub_category}
                      >
                        {subcategory.sub_cat_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="aTitle"
                name="aTitle"
                autoComplete="ad title"
                value={adname}
                sx={{ mb: 2 }}
                onChange={handleTitle}
              />

              <Textarea
                minRows={2}
                maxRows={10}
                placeholder="Type Ad Description"
                sx={{ mb: 2 }}
                label="Ad Description"
                value={addescription}
                onChange={handleDescription}
              />

              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={5}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Condition
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="4"
                        checked={selectedvalue == "4"}
                        onChange={(event) => handleRadio(event)}
                        control={<Radio />}
                        label="New"
                      />

                      <FormControlLabel
                        value="3"
                        checked={selectedvalue == "3"}
                        onChange={(event) => handleRadio(event)}
                        control={<Radio />}
                        label="Used"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Set Price
                  </FormLabel>
                  <Paper
                    component="form"
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                  >
                    <span style={{ padding: "5px" }}>LKR</span>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="1000.00"
                      inputProps={{ "aria-label": "search google maps" }}
                      value={price}
                      onChange={handlePrice}
                    />
                  </Paper>
                </Grid>
              </Grid>

              <FormControl fullWidth size="small" sx={{ mb: 6 }}>
                <InputLabel id="demo-select-small">Location</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="aCategory"
                  value={locationid}
                  label="Select Location"
                  fullWidth
                  onChange={handleLocation}
                >
                  {locationset &&
                    locationset.map((location) => (
                      <MenuItem
                        key={location.id_area}
                        value={location.area_name}
                      >
                        {location.area_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Typography variant="paragraph" sx={{ fontSize: 18 }}>
                Attached Photos
              </Typography>

              <ImageList
                sx={{ width: 500, height: 250, mb: 4 }}
                cols={3}
                rowHeight={164}
              >
                {adimglist.map((item, index) => (
                  <ImageListItem key={item.id_ads_image}>
                    <img
                      src={`/${item.img_url}`}
                      srcSet={`/${item.img_url}`}
                      alt={item.image_name}
                      loading="lazy"
                    />
                    <button
                      onClick={() => removeImage(item.id_ads_image, index)}
                    >
                      X
                    </button>
                  </ImageListItem>
                ))}
              </ImageList>

              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">Sport Type</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="sportType"
                  value={stype}
                  label="Select Sport Type"
                  fullWidth
                  onChange={handleSportType}
                >
                  {sporttype &&
                    sporttype.map((sport) => (
                      <MenuItem
                        key={sport.id_sport_type}
                        value={sport.id_sport_type}
                      >
                        {sport.type_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h5" sx={{ ml: 5 }}>
              Translate The Ad
            </Typography>

            <Box noValidate sx={{ mt: 1, padding: 5 }}>
              <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                <InputLabel id="demo-select-small">කාණ්ඩය</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="aCategory"
                  label="කාණ්ඩය"
                  fullWidth
                  onChange={(event) => loadSubCategory(event)}
                >
                  {categorysnset &&
                    categorysnset.map((category) => (
                      <MenuItem
                        key={category.id_ads_category}
                        value={category.id_ads_category}
                      >
                        {category.category_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">උප කාණ්ඩය</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="aCategory"
                  label="උප කාණ්ඩය"
                  fullWidth
                  onChange={(event) => handleSubCategorySN(event)}
                >
                  {subcategorysnset &&
                    subcategorysnset.map((subcategory) => (
                      <MenuItem
                        key={subcategory.id_sub_category}
                        value={subcategory.id_sub_category}
                      >
                        {subcategory.sub_cat_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="aTitle"
                name="aTitle"
                autoComplete="ad title"
                label="මාතෘකාව"
                sx={{ mb: 2 }}
                onChange={(event) => handleTitleSN(event)}
              />

              <Textarea
                minRows={2}
                maxRows={10}
                label="විස්තරය"
                placeholder="විස්තරය"
                sx={{ mb: 2 }}
                onChange={(event) => handleDescriptionSN(event)}
              />

              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={7}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      තත්ත්වය
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="10"
                        onChange={(event) => handleRadioSN(event)}
                        control={<Radio />}
                        label="නව"
                      />

                      <FormControlLabel
                        value="9"
                        onChange={(event) => handleRadioSN(event)}
                        control={<Radio />}
                        label="භාවිතා කර ඇත"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    මිල
                  </FormLabel>
                  <Paper
                    component="form"
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                  >
                    <span style={{ padding: "5px" }}>රු</span>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="1000.00"
                      inputProps={{ "aria-label": "search google maps" }}
                      value={price}
                      onChange={(event) => handlePrice(event)}
                    />
                  </Paper>
                </Grid>
              </Grid>

              <FormControl fullWidth size="small" sx={{ mb: 6 }}>
                <InputLabel id="demo-select-small">ස්ථානය</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="aCategory"
                  label="ස්ථානය"
                  fullWidth
                  onChange={(event) => handleLocationSN(event)}
                >
                  {locationsnset &&
                    locationsnset.map((location) => (
                      <MenuItem
                        key={location.id_area}
                        value={location.area_name}
                      >
                        {location.area_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Typography variant="paragraph" sx={{ fontSize: 18 }}>
                අමුණා ඇති ඡායාරූප
              </Typography>

              <ImageList
                sx={{ width: 500, height: 250, mb: 4 }}
                cols={3}
                rowHeight={164}
              >
                {adimglist.map((item) => (
                  <ImageListItem key={item.id_ads_image}>
                    <img
                      src={`/${item.img_url}`}
                      srcSet={`/${item.img_url}`}
                      alt={item.image_name}
                      loading="lazy"
                    />
                    <button onClick={() => removeImage(item.id_ads_image)}>
                      X
                    </button>
                  </ImageListItem>
                ))}
              </ImageList>

              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">ක්‍රීඩා වර්ගය</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="sportType"
                  label="ක්‍රීඩා වර්ගය"
                  fullWidth
                  onChange={handleSportTypeSN}
                >
                  {sporttypesn &&
                    sporttypesn.map((sport) => (
                      <MenuItem
                        key={sport.id_sport_type}
                        value={sport.id_sport_type}
                      >
                        {sport.type_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid sx={{ float: "right", mt: 10 }}>
          <Button variant="outlined" className="btnNext">
            Cancel
          </Button>
          <Button variant="outlined" className="btnNext" onClick={handleSubmit}>
            Next
          </Button>
        </Grid>
      </Sidebar>
    </>
  );
}
