import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import Stack from "@mui/joy/Stack";
import LinearProgress from "@mui/joy/LinearProgress";
import Sidebar from "../components/sidebar";

function createData(
  image: string,
  name: string,
  email: string,
  voucher: number,
  date: string
) {
  return { image, name, email, voucher, date };
}

const rows = [
  createData(
    "https://i.picsum.photos/id/541/200/200.jpg?hmac=FPYg43H-9McYNZu4fnac6itnn3Ly9eOJRpZLLnyXuVY",
    "Jane Doe",
    "jandoe@gmail.com",
    "BigBill100",
    "2023-01-21"
  ),
];

const Vouchers = () => {
  return (
    <div>
      <Sidebar>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography variant="h6">Manage Voucher Codes</Typography>
                  <Typography variant="span" sx={{ color: "#a8a5a5" }}>
                    Our Admin can create voucher codes from here
                  </Typography>

                  <Grid container spacing={4} sx={{ mt: 1 }}>
                    <Grid item xs={12} md={6}>
                      <CardContent>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead
                              sx={{
                                "& th": {
                                  color: "rgba(96, 96, 96)",
                                  backgroundColor: "#e2e2e2",
                                },
                              }}
                            >
                              <TableRow>
                                <TableCell align="center">
                                  Voucher Codes
                                </TableCell>
                                <TableCell align="center">Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                key="vouchers"
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  GETOFF75
                                </TableCell>
                                <TableCell align="right">
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{ justifyContent: "center" }}
                                  >
                                    <Grid item xs={12} sm={12} md={6}>
                                      <Chip
                                        label="Deactive"
                                        sx={{
                                          background: "#f9edea",
                                          color: "#fc6748",
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                      <DeleteSweepIcon />
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key="vouchers"
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                >
                                  BIGBILL
                                </TableCell>
                                <TableCell align="right">
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{ justifyContent: "center" }}
                                  >
                                    <Grid item xs={12} sm={12} md={6}>
                                      <Chip
                                        label="Active"
                                        sx={{
                                          background: "#d4f2e6",
                                          color: "#01C67A",
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                      <DeleteSweepIcon />
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <CardContent>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead
                              sx={{
                                "& th": {
                                  color: "rgba(96, 96, 96)",
                                  backgroundColor: "#e2e2e2",
                                },
                              }}
                            >
                              <TableRow>
                                <TableCell align="left">Vouchers</TableCell>
                                <TableCell align="left">Usage</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                key="vouchers"
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="left"
                                >
                                  GETOFF75
                                </TableCell>
                                <TableCell align="right">
                                  <Stack spacing={2} sx={{ flex: 1 }}>
                                    <LinearProgress
                                      determinate
                                      value={25}
                                      sx={{ color: "#FC6748" }}
                                    />
                                  </Stack>
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key="vouchers"
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="left"
                                >
                                  BIGBILL
                                </TableCell>
                                <TableCell align="right">
                                  <LinearProgress
                                    determinate
                                    value={50}
                                    sx={{ color: "#22bb33" }}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ padding: 2 }}>
                    User who used voucher codes
                  </Typography>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Profile Picture</TableCell>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">Email</TableCell>
                          <TableCell align="center">Voucher used</TableCell>
                          <TableCell align="center">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                  <Avatar
                                    alt="Remy Sharp"
                                    src="/static/images/avatar/1.jpg"
                                    sx={{ width: 50, height: 50 }}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">{row.voucher}</TableCell>
                            <TableCell align="center">{row.date}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Sidebar>
    </div>
  );
};

export default Vouchers;
