import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import axios from "axios";

const theme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const [username, Username] = useState("");
  const [password, Password] = useState("");

  const handleUsername = (event) => {
    Username(event.target.value);
  };

  const handlePassword = (event) => {
    Password(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_BASE_URL + "api/user/login", {
        email: username,
        password: password,
        user_type: "Personal",
      })
      .then((res) => {
        if (res.data.response !== null) {
          window.localStorage.setItem("token", res.data.token);
          navigate("dashboard");
        } else {
          alert("Passwrod is Incorrect!!");
        }
      })
      .catch((err) => {
        alert("User Name or Password is Incorrect!!");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="logo" sx={{ width: 100 }} />

          <Typography component="h5">
            Please Enter Your Admin Credentials To Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleUsername}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePassword}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                background: "#ff7300",
                color: "#fff",
                width: "100%",
                borderRadius: "20px",
                height: "40px",
              }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
