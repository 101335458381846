import React from "react";
import Sidebar from "../components/sidebar";

const Banners = () => {
  return (
    <div>
      <Sidebar>
        <h1>This is Banners</h1>
      </Sidebar>
    </div>
  );
};

export default Banners;
