import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ImageList,
  ImageListItem,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const handleSubmit = () => {
  alert("Submitted!");
};

const handleChange = () => {
  alert("selected value");
};

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Bike",
  },
];

const EditAdvertisement = () => {
  const effectRan = useRef(false);
  const [singleads, singleAds] = useState([]);
  const [singleadsimages, singleAdsImages] = useState([]);

  
alert("====");

  let {id} = useParams();
  // let { catId } = useParams();
  // let { subCatId } = useParams();

  const navigate = useNavigate();

  const nextClick = () => {
    navigate("../advertisement_payment");
  };

  useEffect(() => {
    if (effectRan.current === false) {
      axios
        .post(process.env.REACT_APP_BASE_URL+"api/ads-info/get-ads-by-adsId", {
          uid: 1,
          adsId: id,
        })
        .then((res) => {
          singleAds(res.data.response);
          singleAdsImages(res.data.response.imgList);
        })
        .catch((err) => {
          console.log(err);
        });
      effectRan.current = true;
    }
  }, [id]);

  console.log(singleads);
  console.log(singleadsimages);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="h5">Edit the Ad</Typography>

          <Box onSubmit={handleSubmit} noValidate sx={{ mt: 1, padding: 5 }}>
            <FormControl fullWidth size="small" sx={{ mb: 2 }}>
              <InputLabel id="demo-select-small">Select Category</InputLabel>
              <Select
                labelId="demo-select-small"
                id="aCategory"
                value="0"
                label="Select Category"
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value="0">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Vehicle</MenuItem>
                <MenuItem value={20}>Electronic</MenuItem>
                <MenuItem value={30}>Real State</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">
                Select Sub Category
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="aSCategory"
                value="0"
                label="Select Sub Category"
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value="0">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Car</MenuItem>
                <MenuItem value={20}>Mobile</MenuItem>
                <MenuItem value={30}>Bare Land</MenuItem>
              </Select>
            </FormControl>

            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              id="aTitle"
              name="aTitle"
              label="Ad Title"
              autoComplete="ad title"
              sx={{ mb: 2 }}
            />

            <Textarea
              minRows={2}
              placeholder="Type Ad Description"
              sx={{ mb: 2 }}
            />

            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={5}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Condition
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Used"
                      control={<Radio />}
                      label="Used"
                    />
                    <FormControlLabel
                      value="New"
                      control={<Radio />}
                      label="New"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Set Price
                </FormLabel>
                <Paper
                  component="form"
                  sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                >
                  <span style={{ padding: "5px" }}>LKR</span>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="1000.00"
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                </Paper>
              </Grid>
            </Grid>

            <FormControl fullWidth size="small" sx={{ mb: 6 }}>
              <InputLabel id="demo-select-small">Location</InputLabel>
              <Select
                labelId="demo-select-small"
                id="aCategory"
                value="0"
                label="Select Location"
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value="0">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Colombo</MenuItem>
                <MenuItem value={20}>Kalutara</MenuItem>
                <MenuItem value={30}>Galle</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="paragraph" sx={{ fontSize: 18 }}>
              Attached Photos
            </Typography>

            <ImageList
              sx={{ width: 500, height: 450 }}
              cols={3}
              rowHeight={164}
            >
              {itemData.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          translate section
        </Grid>
      </Grid>

      <Grid sx={{ float: "right", mt: 10 }}>
        <Button variant="outlined" className="btnNext">
          Cancel
        </Button>
        <Button variant="outlined" className="btnNext" onClick={nextClick}>
          Next
        </Button>
      </Grid>
    </div>
  );
};

export default EditAdvertisement;
