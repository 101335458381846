import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { InboxOutlined } from "@mui/icons-material";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import CategoryIcon from "@mui/icons-material/Category";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import VerifiedIcon from "@mui/icons-material/Verified";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import logo from "../assets/images/logo.png";

const drawerWidth = 240;

export default function ResponsiveDrawer({ children }) {
  const { window } = Window;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () =>{
    localStorage.clear();
    navigate('/');
  }

  const menuItem = [
    {
      name: "Dashboard",
      icon: <InboxOutlined />,
      path: "/dashboard",
    },
    {
      name: "Advertisement",
      icon: <FontDownloadIcon />,
      path: "/advertisement/en",
    },
    {
      name: "S.Advertisement",
      icon: <FontDownloadIcon />,
      path: "/advertisementsn/sn",
    },
    {
      name: "Catalogue",
      icon: <CategoryIcon />,
      path: "/catalouge",
    },
    {
      name: "Banners",
      icon: <ViewCarouselIcon />,
      path: "/banners",
    },
    {
      name: "Vouchers",
      icon: <CardGiftcardIcon />,
      path: "/vouchers",
    },
    {
      name: "Membership",
      icon: <CardMembershipIcon />,
      path: "/membership",
    },
    {
      name: "Verification Center",
      icon: <VerifiedIcon />,
      path: "/verification",
    },
    {
      name: "Admin Users",
      icon: <PeopleIcon />,
      path: "/admin",
    },
    {
      name: "Settings",
      icon: <SettingsIcon />,
      path: "/settings",
    },
  ];

  const drawer = (
    <div>
      <Toolbar>
        <Link to={'/dashboard'}>
        <img src={logo} alt="logo" className="logo" />
        </Link>
      </Toolbar>
      <Divider />

      <div>
        <p className="feature_tools">Feature tools</p>
        <List>
          {menuItem.map((item, index) => (
            <ListItem key={item.name}>
              <NavLink
                to={item.path}
                key={index}
                className="link"
                activeclassname="active"
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}

          <div>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#f9edea",
                width: "75%",
                mt:4, ml: 3
              }}
              onClick={logout}
            >
              LogOut
            </Button>
          </div>
        </List>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: "#fff",
          color: "#000",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">Welcome Back Admin</Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <main>{children}</main>
      </Box>
    </Box>
  );
}
