import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormHelperText,
  Grid,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
// import React, { useState } from "react";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircleTwoTone";
import Sidebar from "../components/sidebar";

export default function BasicModal() {
  // const [loginName, setLoginName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("worked!");
  };

  const handleClick = () => {
    alert("Deactivate Button");
  };

  const card = (
    <React.Fragment>
      <CardContent>
        <ListItemText
          sx={{ mb: 2 }}
          primary={
            <div>
              <span
                style={{ fontSize: 16, fontWeight: "bold", color: "#2EC6C8" }}
              >
                SILVER
              </span>
              <span style={{ float: "right" }}>
                <CheckCircleIcon sx={{ color: "#2EC6C8" }} />
              </span>
            </div>
          }
        />
        <Typography sx={{ fontSize: 14, color: "#2EC6C8" }} gutterBottom>
          Ads -10/ 100
        </Typography>
        <Typography sx={{ fontSize: 14, color: "#2EC6C8" }} gutterBottom>
          Own Onlien Store
        </Typography>
        <Typography sx={{ fontSize: 14, color: "#2EC6C8" }}>
          Membership Badge
        </Typography>
      </CardContent>
      <CardActions>
        <Chip label="Deactivate" variant="outlined" onClick={handleClick} />
      </CardActions>
    </React.Fragment>
  );

  return (
    <div>
      <Sidebar>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: "10px",
                  background: "#F8F8F8",
                  padding: "20px",
                }}
              >
                <CardContent>
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="mType"
                      name="mType"
                      label="Membership Type"
                      autoComplete="membership type"
                      autoFocus
                      size="small"
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="mAdsQuota"
                      name="mAdsQuota"
                      label="Ads Quota"
                      autoComplete="ads quota"
                      size="small"
                    />

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="mStatus"
                      name="mStatus"
                      label="Membership Status"
                      autoComplete="membership status"
                      size="small"
                    />
                    <FormHelperText id="my-helper-text" sx={{ float: "right" }}>
                      Renewal at 22 April 2022
                    </FormHelperText>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="mMethod"
                          name="mMethod"
                          label="Payment Method"
                          autoComplete="payment method"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          size="small"
                        >
                          Download Slip
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ borderRadius: "10px" }}>
                <Grid container sx={{ mt: 3 }}>
                  <Grid item xs={4} sm={4}></Grid>
                  <Grid item xs={4} sm={4}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: 110, height: 110 }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}></Grid>
                </Grid>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ textAlign: "center" }}
                  >
                    John Doe
                  </Typography>
                  <Typography
                    variant="span"
                    component="div"
                    sx={{ textAlign: "center", mb: 3 }}
                  >
                    johndoe@gmail.com
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ textAlign: "center", mb: 6 }}
                  >
                    Gold Member - 100
                  </Typography>

                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Chip
                      label="Verified"
                      sx={{
                        background: "#a4e6c1",
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={5}>
              <Box sx={{ minWidth: 100 }}>
                <Card
                  variant="outlined"
                  sx={{ background: "#E9F7F8", borderRadius: "10px" }}
                >
                  {card}
                </Card>
              </Box>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="button"
                    variant="outlined"
                    disableRipple
                    disableFocusRipple
                    sx={{
                      mt: 3,
                      mb: 2,
                      background: "#f9edea",
                      color: "#fc6748",
                      borderColor: "#f9edea",
                    }}
                  >
                    Remove Verify Status
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    type="button"
                    variant="outlined"
                    sx={{
                      mt: 3,
                      mb: 2,
                      background: "#f9edea",
                      color: "#fc6748",
                      borderColor: "#f9edea",
                    }}
                  >
                    Remove Membership
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Sidebar>
    </div>
  );
}
