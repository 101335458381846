import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import axios from "axios";

const DashboardCards = () => {
  const effectRan = useRef(false);
  const [counts, setCount] = useState([]);

  useEffect(() => {
    if (effectRan.current === false) {
      axios
        .post(process.env.REACT_APP_BASE_URL+"api/ads-info/all-ads-count-info", {
          lanCode: "EN",
        })
        .then((res) => {
          setCount(res.data.response);
        })
        .catch((err) => {
          console.log(err);
        });
      effectRan.current = true;
    }
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Total Ads
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              {counts.all_ads_count}
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Live Ads
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              {counts.active_ads_count}
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Pending Ads
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              {counts.pendind_ads_count}
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Rejected Ads
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              {counts.rejected_ads_count}
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Total Member
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              0
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Paid Member
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              0
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Pending Member
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              0
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Unpaid Member
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              0
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Top Ads
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              0
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card>
            <CardContent sx={{ padding: "5px" }}>
              <Typography variant="h6" component="div">
                Sponsor Ads
              </Typography>
            </CardContent>
            <Typography
              variant="paragraph"
              style={{ float: "right", padding: "10px" }}
            >
              0
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardCards;
